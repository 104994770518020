<template>
  <div>
    <form-create
      :is-add-new-sidebar-active.sync="isAddNewSidebarActive"
      titulo="Novo Formato"
      @refetch-data="refetchData"
    />

    <b-card
      no-body
      class="mb-0"
    >
      <h5 class="card-title pt-2 pl-2 pb-0 mb-0">
        Lista de formatos do projeto
      </h5>
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>

          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />
              <b-button
                variant="success"
                @click="isAddNewSidebarActive = true"
              >
                <span class="text-nowrap">
                  Adicionar
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        small
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="editarItem(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Editar</span>
            </b-dropdown-item>

            <b-dropdown-item @click="modalExcluir(data.item)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Excluir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosItem.uuid"
        :item-nome="dadosItem.descricao"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
      />
    </b-card>

    <b-modal
      id="modal-editar"
      v-model="modalShow"
      :title="`Editar ${dadosItem.nome}`"
      :no-close-on-backdrop="true"
    >
      <validation-provider
        #default="validationContext"
        name="Nome do Formato"
        rules="required"
      >
        <b-form-group
          label="Nome do Formato"
          label-for="nome-formato"
        >
          <b-form-input
            id="nome-formato"
            v-model="dadosItem.nome"
            name="formato"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            placeholder="Informe o nome do formato"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>
      </validation-provider>
      <validation-provider
        #default="validationContext"
        name="abreviacao"
        rules="required"
      >
        <b-form-group
          label="Informe a Abreviação"
          :state="getValidationState(validationContext)"
        >
          <b-form-input
            id="abreviacao"
            v-model="dadosItem.abreviacao"
            name="abreviacao"
            autofocus
            :state="getValidationState(validationContext)"
            trim
            placeholder="Informe o nome da Abreviação"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <template #modal-footer>
        <div class="w-100">
          <b-button
            variant="primary"
            size="md"
            class="float-left"
            @click="modalShow = false"
          >
            Fechar
          </b-button>
          <b-button
            variant="success"
            size="md"
            class="float-right"
            @click="salvarItemEditado(dadosItem)"
          >
            Salvar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BPagination,
  BRow,
  BSpinner,
  BTable,
} from 'bootstrap-vue'

import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import FormCreate from './FormCreate.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import selectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import store from '@/store'
import useItemList from './useItemList'
import vSelect from 'vue-select'
import formatosStoreModule from '../formatosStoreModule'

export default {
  components: {
    FormCreate,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    BFormInvalidFeedback,
    BFormGroup,
    vSelect,
    ValidationProvider,
    selectComPesquisa,
  },

  setup() {
    const blankUserData = {
      nome: '',
      abreviacao: '',
    }

    const ITEM_APP_STORE_MODULE_NAME = 'app-projeto-formatos'

    // Register module
    if (!store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.registerModule(ITEM_APP_STORE_MODULE_NAME, formatosStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ITEM_APP_STORE_MODULE_NAME)) store.unregisterModule(ITEM_APP_STORE_MODULE_NAME)
    })

    const itemData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetItemData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const isAddNewSidebarActive = ref(false)

    const {
      fetchDados,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      deleteItem,
      update,
    } = useItemList()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetItemData)

    return {
      isAddNewSidebarActive,
      deleteItem,
      fetchDados,
      update,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      refFormObserver,
      getValidationState,
      resetForm,
      itemData,

    }
  },
  data() {
    return {
      dadosItem: {
        uuid: '',
        nome: '',
        abreviacao: '',

      },

      modalShow: false,

    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-projeto-formatos'].load
    },
    items() {
      return this.fetchDados
    },
  },

  methods: {
    editarItem(item) {
      this.dadosItem = { ...item }
      this.modalShow = true
    },

    excluirItem(dados) {
      this.deleteItem(dados)
    },

    modalExcluir(item) {
      this.dadosItem.nome = item.nome
      this.dadosItem.abreviacao = item.abreviacao
      this.dadosItem.uuid = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },

    salvarItemEditado(item) {
      this.$root.$emit('bv::hide::modal', 'modal-editar')
      this.update(item)
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
