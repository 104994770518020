import axios from '@axios'

export default {
  namespaced: true,
  state: {
    load: false,
  },
  getters: {
    getLoad(state) {
      return state.load
    },
  },
  mutations: {
    alteraLoading(state, data) {
      state.load = data
    },
  },
  actions: {
    deleteItem(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/projeto-formatos/${id}`)
          .then(response => resolve())
          .catch(error => reject(error))
      })
    },

    fetchDados({ commit }, queryParams) {
      commit('alteraLoading', true)
      return new Promise((resolve, reject) => {
        axios
          .get('/projeto-formatos', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('alteraLoading', false)
          })
          .catch(error => console.log(error))
      })
    },

    store(ctx, itemData) {
      const formData = new FormData()

      formData.append('abreviacao', itemData.abreviacao)
      formData.append('nome', itemData.descricao)

      return new Promise((resolve, reject) => {
        axios
          .post('/projeto-formatos', formData)
          .then(response => resolve(response => {
          }))
          .catch(error => reject(error))
      })
    },

    show(ctx, item) {
      return new Promise((resolve, reject) => {
        axios
          .get(`projeto-formatos/${item.id.uuid}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    update(ctx, itemData) {
      const formData = new FormData()

      formData.append('nome', itemData.nome)
      formData.append('abreviacao', itemData.abreviacao)

      formData.append('_method', 'PUT')
      return new Promise((resolve, reject) => {
        axios
          .post(`projeto-formatos/${itemData.uuid}`, formData)
          .then(response => resolve(response))
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
